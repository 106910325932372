import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import http from '../../services/httpService';

import EmployeeModal from '../employee/employeeModal';

import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Page from '../common/page';
import { Mobile, NotMobile } from '../common/responsive';

function EmployeeDashboard() {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);
  const [showDeleteEmployee, setShowDeleteEmployee] = useState(false);
  const [showRegistrationEmailConfirmation, setShowRegistrationEmailConfirmation] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getEmployees = async () => {
      try {
        setIsLoading(true);
        const response = await http.get('/employee/getActive');
        setEmployees(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getEmployees();
  }, []);

  const handleDeactivateEmployee = async () => {
    await http.delete(`/employee/deactivate?id=${selectedEmployee.id}`);
    navigate(0);
  };

  const handleSendRegistrationEmail = async () => {
    await http.post(`/employee/sendRegistrationEmail?employee_id=${selectedEmployee.id}`);
    setShowRegistrationEmailConfirmation(false);
  };

  const registrationEmailButton = employee => {
    return (
      <button
        className="btn-text-error sura font-18 fw-normal"
        onClick={() => {
          setSelectedEmployee(employee);
          setShowRegistrationEmailConfirmation(true);
        }}
      >
        <Icon name="on_hold" tooltipText="Account has not been registered" />
        <span className="font-14">Send Registration Email</span>
      </button>
    );
  };

  const editButton = employee => {
    return (
      <button
        className="btn-text-primary sura font-18 fw-normal"
        onClick={() => {
          setShowUserDetails(true);
          setSelectedEmployee(employee);
        }}
      >
        <Icon name="edit" />
      </button>
    );
  };

  const deleteButton = employee => {
    return (
      <button
        className="btn-text-error sura font-18 fw-normal"
        onClick={() => {
          setShowDeleteEmployee(true);
          setSelectedEmployee(employee);
        }}
      >
        <Icon name="delete" tooltipText="Deactivate" />
      </button>
    );
  };

  const newButton = (
    <button
      type="button"
      className="btn-rounded-primary align-self-end"
      onClick={() => setShowCreateEmployee(true)}
    >
      <Icon name="add" />
      New Employee
    </button>
  );

  return (
    <Page selectedTab="admin">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        <h1 className="pb-4">Employees</h1>
        {isLoading && <i className="flex-centered m-5 fa fa-circle-notch fa-spin fa-2x subtle" />}
        {!isLoading && errorMessage && <p className="error">{errorMessage}</p>}
        {!isLoading && !errorMessage && (
          <React.Fragment>
            <Mobile>
              <React.Fragment>
                {newButton}
                {employees.map((t, i) => (
                  <div key={i} className="d-flex flex-column border rounded p-3 mt-3">
                    <div className="flex-row-aligned justify-content-between">
                      <h2>
                        {t.last_name}, {t.first_name}
                      </h2>
                      <div className="flex-row-aligned">
                        <div className="me-2">{editButton(t)}</div>
                        {deleteButton(t)}
                      </div>
                    </div>
                    {t.username && (
                      <span className="mt-2 ps-3">
                        <span className="sura me-2">Username:</span>
                        {t.username}
                      </span>
                    )}
                    {!t.username && <span className="mt-2 ps-3">{registrationEmailButton(t)}</span>}
                    <span className="ps-3">
                      <span className="sura me-2">Email:</span>
                      {t.email}
                    </span>
                    <span className="ps-3">
                      <span className="sura me-2">Role:</span>
                      {t.role}
                    </span>
                  </div>
                ))}
              </React.Fragment>
            </Mobile>
            <NotMobile>
              <React.Fragment>
                <div className="w-100 mb-4">
                  <table className="w-100">
                    <thead className="border-bottom border-top">
                      <tr>
                        <th className="py-3 px-2">Name</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.map((t, i) => (
                        <tr key={i} className={i % 2 === 0 ? `background-gray` : ''}>
                          <td className="p-2">
                            {t.first_name} {t.last_name}
                          </td>
                          <td>{t.username ? t.username : registrationEmailButton(t)}</td>
                          <td>{t.email}</td>
                          <td>{t.role}</td>
                          <td>{editButton(t)}</td>
                          <td>{deleteButton(t)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {newButton}
              </React.Fragment>
            </NotMobile>
          </React.Fragment>
        )}

        {showUserDetails && (
          <EmployeeModal
            employee={selectedEmployee}
            handleClose={() => setShowUserDetails(false)}
          />
        )}
        {showCreateEmployee && <EmployeeModal handleClose={() => setShowCreateEmployee(false)} />}
        {showDeleteEmployee && (
          <Confirmation
            message={`Are you sure you want to deactivate ${selectedEmployee.first_name} ${selectedEmployee.last_name}? If you proceed, the employee's account will be deactivated and they will no longer have access to the
          Portal.`}
            handleConfirm={handleDeactivateEmployee}
            handleClose={() => setShowDeleteEmployee(false)}
          />
        )}
        {showRegistrationEmailConfirmation && (
          <Confirmation
            message="Are you sure you would like to send registration email to employee? Email will be valid for 3 hours."
            handleConfirm={handleSendRegistrationEmail}
            handleClose={() => setShowRegistrationEmailConfirmation(false)}
          />
        )}
      </div>
    </Page>
  );
}

export default EmployeeDashboard;
