import React from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from '../common/icon';
import Page from '../common/page';

function Admin() {
  const navigate = useNavigate();

  return (
    <Page selectedTab="admin">
      <div className="background-white box-shadow flex-grow-1">
        <div className="p-5">
          <button onClick={() => navigate('/admin/employee-dashboard')}>
            <Icon name="fa fa-user" />
            <span className="hover-underline">Employee Dashboard</span>
          </button>
          <button className="mt-1" onClick={() => navigate('/timesheets')}>
            <Icon name="fa fa-clock" />
            <span className="hover-underline">Employee Time Cards</span>
          </button>
          <button className="mt-1" onClick={() => navigate('/review-dashboard')}>
            <Icon name="fa fa-brands fa-google" />
            <span className="hover-underline">Review Dashboard</span>
          </button>
          <button className="mt-1" onClick={() => navigate('/admin/missed-alerts-report')}>
            <Icon name="fa fa-times" />
            <span className="hover-underline">Missed Alerts Report</span>
          </button>
          <button className="mt-1" onClick={() => navigate('/admin/doctor-schedule')}>
            <Icon name="fa fa-calendar" />
            <span className="hover-underline">Doctor Schedule</span>
          </button>
          <button className="mt-1" onClick={() => navigate('/admin/pressure-gauge')}>
            <span className="hover-underline">Pressure Gauge</span>
          </button>
          <button className="mt-1" onClick={() => navigate('/admin/delinquency-dashboard')}>
            <span className="hover-underline">Delinquency Dashboard</span>
          </button>
        </div>
      </div>
    </Page>
  );
}

export default Admin;
