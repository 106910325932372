import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import InputCustom from '../common/inputCustom';
import InputEitherOr from '../common/inputEitherOr';
import InputSelect from '../common/inputSelect';
import InputTextarea from '../common/inputTextarea';

import EstimateConstants from '../../estimateConstants';

function SurgeryScheduleModal({ block, surgery, scheduleColumns, handleClose }) {
  const locationId = localStorage.getItem('locationId');
  const isLoveland = Number(locationId) === 1;
  const isUpdate = surgery && surgery.id;

  const getSuggestedStartTime = () => {
    const dropOffTimes = block.surgeries.map(t =>
      moment(t.start).tz('America/Denver').format('hh:mm')
    );
    const earlyTime = isLoveland ? '07:30' : '07:15';
    const lateTime = isLoveland ? '07:45' : '07:30';

    const earlyCount = dropOffTimes.filter(t => t === earlyTime).length;
    const lateCount = dropOffTimes.filter(t => t === lateTime).length;
    return lateCount < earlyCount ? lateTime : earlyTime;
  };

  const [pet, setPet] = useState(null);

  const [scheduleColumnId, setScheduleColumnId] = useState(
    block ? block.schedule_column_id : surgery.schedule_column_id
  );
  const [doctorShifts, setDoctorShifts] = useState([]);
  const [doctor, setDoctor] = useState('Unassigned');
  const [date, setDate] = useState(
    moment(block ? block.start : surgery.start)
      .tz('America/Denver')
      .format('YYYY-MM-DD')
  );
  const [startTime, setStartTime] = useState(
    block ? getSuggestedStartTime() : moment(surgery.start).tz('America/Denver').format('hh:mm')
  );
  const [surgeryType, setSurgeryType] = useState(surgery ? Number(surgery.surgeryTypeId) : 0);
  const [blocksPrimary, setBlocksPrimary] = useState(
    surgery ? surgery.numBlocks - (surgery.numBlocksSecondary || 0) : 1
  );
  const [blocksSecondary, setBlocksSecondary] = useState(
    surgery ? surgery.numBlocksSecondary || 0 : 0
  );
  const [description, setDescription] = useState(
    surgery
      ? surgery.complaint
        ? surgery.complaint.split(' ').slice(2).join(' ')
        : surgery.surgeryTypeId < 4
        ? EstimateConstants.SURGERY_TYPE_MAP[surgery.surgeryTypeId].name
        : ''
      : ''
  );
  const [isCancellationList, setIsCancellationList] = useState(
    surgery && surgery.isCancellationList ? surgery.isCancellationList : ''
  );
  const [isUrgent, setIsUrgent] = useState(surgery ? surgery.isUrgent : 'N');
  const [notes, setNotes] = useState(surgery && surgery.notes ? surgery.notes : '');
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(true);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getDoctorShifts = async () => {
      try {
        const response = await http.get(
          `/schedule/getDoctorShifts?start_date=${date}&end_date=${date}&location_id=${locationId}`
        );

        const doctorShifts = response.data;
        const shift = doctorShifts.find(
          t => Number(t.schedule_column_id) === Number(scheduleColumnId)
        );

        setDoctor(shift ? shift.last_name : 'Unassigned');
        setDoctorShifts(doctorShifts);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    getDoctorShifts();
  }, [date, locationId, scheduleColumnId]);

  const handlePetChange = async selectedPet => {
    try {
      setErrorMessage('');
      const { data: pet } = await http.get(`/pet/getSurgerySchedInfo?pet_id=${selectedPet.id}`);
      setPet(pet);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const handleScheduleColumnChange = async scheduleColumnId => {
    setScheduleColumnId(scheduleColumnId);

    const shift = doctorShifts.find(t => Number(t.schedule_column_id) === Number(scheduleColumnId));
    setDoctor(shift ? shift.last_name : 'Unassigned');
  };

  const handleSubmit = async () => {
    const params = {
      scheduleColumnId,
      date,
      startTime,
      blocks: Number(blocksPrimary) + Number(blocksSecondary),
      blocksSecondary,
      surgeryType,
      description,
      isUrgent,
      isCancellationList,
      notes,
      sendConfirmationEmail: sendConfirmationEmail ? 'Y' : 'N',
    };

    try {
      if (isUpdate) {
        params.appointmentId = surgery.id;
        await http.post(`/surgery/updateSurgery`, params);
      } else {
        params.petId = pet.id;
        await http.post(`/surgery/scheduleSurgery`, params);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }

    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        {pet && !pet.hasHp && (
          <p className="mb-2 ms-1 orange sura">
            <Icon name="fa fa-exclamation-circle" />
            Patient does not have a HP
          </p>
        )}
        <InputCustom
          selectedId={isUpdate ? surgery.pet_id : null}
          retrievalEndpoint="/pet/getById?pet_id="
          searchEndpoint="/pet/search?"
          displayNameField="displayName"
          label="Pet"
          handleChange={handlePetChange}
          handleClear={() => {
            setPet(null);
          }}
          disableClear={isUpdate}
          disabled={isUpdate}
        />
        <Input
          name="date"
          type="date"
          value={date}
          label="Date"
          onChange={setDate}
          disabled={block}
        />
        <InputSelect
          name="scheduleColumnId"
          value={scheduleColumnId}
          label="Schedule Column"
          optionConfig={scheduleColumns}
          onChange={handleScheduleColumnChange}
          disabled={block}
        />
        {scheduleColumnId && <Input name="doctor" value={doctor} label="Doctor" disabled={true} />}
        {block && (
          <InputSelect
            name="startTime"
            value={startTime}
            label="Drop-Off Time"
            optionConfig={
              isLoveland
                ? ['07:30', '07:45']
                : [
                    '07:00',
                    '07:15',
                    '07:30',
                    '07:45',
                    '08:00',
                    '08:15',
                    '08:30',
                    '08:45',
                    '09:00',
                    '09:15',
                    '09:30',
                  ]
            }
            rawOptions={true}
            onChange={setStartTime}
          />
        )}
        {!block && (
          <Input
            name="startTime"
            type="time"
            value={startTime}
            label="Drop-Off Time"
            onChange={setStartTime}
          />
        )}
        <InputSelect
          name="surgeryType"
          label="Surgery Type"
          value={surgeryType}
          optionConfig={EstimateConstants.SURGERY_TYPE_MAP}
          onChange={value => {
            setSurgeryType(Number(value));
            const surgeryType = EstimateConstants.SURGERY_TYPE_MAP.find(
              t => Number(t.value) === Number(value)
            );
            setDescription(value > 0 && value < 4 && surgeryType ? surgeryType.name : '');
          }}
        />
        <label className="mb-1 ms-2 black">Blocks</label>
        <div className="d-flex input-width">
          <InputSelect
            style={{ width: '20px' }}
            name="blocksPrimary"
            value={blocksPrimary}
            label={surgeryType === 1 ? 'Dental' : 'GA'}
            optionConfig={[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4]}
            rawOptions={true}
            onChange={setBlocksPrimary}
          />
          <span className="mt-4 mx-2 font-18">+</span>
          <InputSelect
            style={{ width: '20px' }}
            name="blocksSecondary"
            value={blocksSecondary}
            label={surgeryType === 1 ? 'GA' : 'Dental'}
            optionConfig={[0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4]}
            rawOptions={true}
            onChange={value => {
              if (blocksSecondary === 0) {
                setDescription('');
              }
              setBlocksSecondary(Number(value));
            }}
          />
          <p className="mt-4 ms-2 font-16">
            <nobr>= {Number(blocksPrimary) + Number(blocksSecondary)}</nobr>
          </p>
        </div>
        {pet && surgeryType === 2 && (
          <p className="mb-2 ms-1 orange sura">
            <Icon name="fa fa-exclamation-circle" />
            Add heat cycle info to notes
          </p>
        )}
        <Input
          name="description"
          label="Description"
          value={description}
          onChange={setDescription}
        />
        <InputSelect
          name="isCancellationList"
          value={isCancellationList}
          label="Reschedule sooner if new opening?"
          optionConfig={[
            { name: 'Select', value: '' },
            { name: 'Yes', value: 'Y' },
            { name: 'No', value: 'N' },
          ]}
          onChange={setIsCancellationList}
        />
        <InputEitherOr
          name="isUrgent"
          label="Is Urgent?"
          value={isUrgent}
          option1={{ name: 'Yes', value: 'Y' }}
          option2={{ name: 'No', value: 'N' }}
          onChange={({ target }) => setIsUrgent(target.value)}
        />
        <InputTextarea name="notes" value={notes} label="Notes" rows="3" onChange={setNotes} />
        {(!isUpdate ||
          !moment
            .tz(surgery.start, 'America/Denver')
            .isSame(moment.tz(`${date}T${startTime}`, 'America/Denver'))) && (
          <InputCheckbox
            className="my-2"
            name="sendConfirmationEmail"
            label="Send Confirmation Email"
            checked={sendConfirmationEmail}
            onChange={setSendConfirmationEmail}
          />
        )}
        <ButtonPanel
          primaryButtonText={isUpdate ? 'Update' : 'Schedule'}
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={
            (!isUpdate && !pet) ||
            !scheduleColumnId ||
            !surgeryType ||
            !description ||
            !isCancellationList
          }
        />
      </form>
    </Modal>
  );
}

export default SurgeryScheduleModal;
