import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/global/react-popper-tooltip.css';
import { usePopperTooltip } from 'react-popper-tooltip';

import http from '../../services/httpService';

import ApplyWaitingPeriod from './applyWaitingPeriod';
import EmployeeHealthPlan from '../contract/employeeHealthPlan';
import PetModal from './petModal';
import RabiesCertificate from './rabiesCertificate';

import Confirmation from '../common/confirmation';
import Icon from '../common/icon';

import Constants from '../../constants';

function AdminPetOptions({ pet }) {
  const [showModal, setShowModal] = useState(null);
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const userIsManager =
    Number(JSON.parse(localStorage.getItem('loggedIn')).role_id) === Constants.MANAGER;

  const handleOverrideWaitingPeriod = async () => {
    await http.post(`/pet/overrideWaitingPeriod?pet_id=${pet.id}`);
    navigate(0);
  };

  const handleDeletePet = async () => {
    await http.delete(`/pet/delete?pet_id=${pet.id}`);
    navigate(`/client/${pet.client_id}`);
    navigate(0);
  };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    trigger: 'click',
    onVisibleChange: () => setVisible(!visible),
  });

  const toggleModal = modalToDisplay => {
    setVisible(false);
    setShowModal(modalToDisplay);
  };

  const dropdownOptions = [
    { text: 'Edit Pet', handleClick: () => toggleModal('edit_pet') },
    { text: 'Email Rabies Certificate', handleClick: () => toggleModal('rabies_certificate') },
    {
      text: 'Create Estimate',
      handleClick: () =>
        navigate('/estimate-generator', {
          state: { pet, redirectPath: `/client/${pet.client_id}/pet/${pet.id}` },
        }),
    },
  ];

  if (userIsManager) {
    if (!pet.isDeceased) {
      if (pet.inWaitingPeriod) {
        dropdownOptions.push({
          text: 'Override Waiting Period',
          handleClick: () => toggleModal('override_waiting_period'),
        });
      } else {
        dropdownOptions.push({
          text: 'Apply Waiting Period',
          handleClick: () => toggleModal('apply_waiting_period'),
        });
      }
    }

    if (!pet.inWaitingPeriod) {
      dropdownOptions.push({
        text: 'Setup Employee Health Plan',
        handleClick: () => toggleModal('employee_hp'),
      });
    }

    dropdownOptions.push({
      text: 'Delete Pet',
      handleClick: () => toggleModal('delete_pet'),
    });
  }

  if (dropdownOptions.length === 0) {
    return null;
  }

  return (
    <div ref={setTriggerRef}>
      <Icon name="options_dots" className="me-0" tooltipText="Admin Options" />
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container options' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <div className="d-flex flex-column">
            {dropdownOptions.map((t, i) => (
              <button key={i} type="button" onClick={t.handleClick}>
                {t.text}
              </button>
            ))}
          </div>
        </div>
      )}
      {showModal === 'edit_pet' && <PetModal pet={pet} handleClose={toggleModal} />}
      {showModal === 'rabies_certificate' && (
        <RabiesCertificate petId={pet.id} clientId={pet.client_id} handleClose={toggleModal} />
      )}
      {showModal === 'override_waiting_period' && (
        <Confirmation
          message="Are you sure you would like to override the waiting period for this pet?"
          handleConfirm={handleOverrideWaitingPeriod}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'apply_waiting_period' && (
        <ApplyWaitingPeriod petId={pet.id} handleClose={toggleModal} />
      )}
      {showModal === 'employee_hp' && (
        <EmployeeHealthPlan petId={pet.id} handleClose={toggleModal} />
      )}
      {showModal === 'delete_pet' && (
        <Confirmation
          message="Are you sure you would like to delete this pet?"
          handleConfirm={handleDeletePet}
          handleClose={toggleModal}
        />
      )}
    </div>
  );
}

export default AdminPetOptions;
