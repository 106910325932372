import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import AppointmentModal from './appointmentModal';
import AppointmentFinderModal from './appointmentFinderModal';
import BlockOffModal from './blockOffModal';
import ScheduleTemplater from './scheduleTemplater';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import InputSelect from '../common/inputSelect';
import Navbar from '../navbar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../styles/components/schedule.scss';

function Schedule() {
  const currentDate = localStorage.getItem('calendarDate');
  const locationId = localStorage.getItem('locationId');
  const isLoveland = Number(locationId) === 1;

  const [appointments, setAppointments] = useState([]);
  const [blockOffs, setBlockOffs] = useState([]);
  const [scheduleColumns, setScheduleColumns] = useState([]);
  const [pressureGaugeStats, setPressureGaugeStats] = useState(null);
  const [date, setDate] = useState(currentDate || moment().format('YYYY-MM-DD'));

  const [showScheduleTemplaterModal, setShowScheduleTemplaterModal] = useState(false);
  const [showPrintTreatmentSheetModal, setShowPrintTreatmentSheetModal] = useState(false);
  const [showAppointmentFinderModal, setShowAppointmentFinderModal] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showBlockOffModal, setShowBlockOffModal] = useState(false);
  const [selectedAppt, setSelectedAppt] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const localizer = momentLocalizer(moment);

  useEffect(() => {
    const getScheduleData = async () => {
      try {
        setIsLoading(true);

        const apptResponse = await http.get(
          `/schedule/getDailyAppointments?date=${date}&location_id=${locationId}`
        );
        const appts = apptResponse.data.map(t => ({
          ...t,
          title: (
            <p style={{ fontFamily: 'unset' }}>
              <Icon name={t.icon_name} tooltipText={t.appointment_status} />
              {t.is_new_pet === 'Y' && (
                <Icon
                  name="fa fa-star"
                  tooltipText={t.appointment_status}
                  style={{ color: '#eed946' }}
                />
              )}
              <strong style={{ color: 'darkRed' }}>
                {t.name} ({t.first_name} {t.last_name})
              </strong>{' '}
              {t.complaint}
            </p>
          ),
          start: new Date(t.start),
          end: new Date(t.end),
          resourceId: String(t.schedule_column_id),
        }));
        setAppointments(appts);

        const blockOffResponse = await http.get(
          `/schedule/getDailyBlockOffs?date=${date}&location_id=${locationId}`
        );
        const blockOffs = blockOffResponse.data.map(t => ({
          ...t,
          title: (
            <p style={{ fontFamily: 'unset' }}>
              <strong style={{ color: '#313030' }}>{t.label || t.block_off_type}</strong>
              {t.notes && (
                <React.Fragment>
                  <br />
                  {t.notes}
                </React.Fragment>
              )}
            </p>
          ),
          start: new Date(t.start),
          end: new Date(t.end),
          resourceId: String(t.schedule_column_id),
          isBlockOff: true,
        }));
        setBlockOffs(blockOffs);

        const scheduleColumnResponse = await http.get(
          `/schedule/getColumns?location_id=${locationId}`
        );

        const doctorShiftResponse = await http.get(
          `/schedule/getDoctorShifts?start_date=${date}&end_date=${date}&location_id=${locationId}`
        );

        const scheduleColumns = scheduleColumnResponse.data;
        const doctorShifts = doctorShiftResponse.data;

        for (const column of scheduleColumns) {
          const matchingShifts = doctorShifts.filter(
            t => Number(t.schedule_column_id) === Number(column.id)
          );
          column.matchingShifts = matchingShifts;
        }

        setScheduleColumns(scheduleColumns);

        const pressureGagueStatsResponse = await http.get(
          `/schedule/getPressureGaugeStats?start_date=${date}&end_date=${date}&location_id=${locationId}`
        );
        setPressureGaugeStats(pressureGagueStatsResponse.data);

        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setIsLoading(false);
      }
    };

    getScheduleData();
  }, [locationId, date]);

  const resources = scheduleColumns.map(t => ({
    resourceId: t.id,
    resourceTitle: (
      <div>
        <p className="roboto">{t.name}</p>
        {t.matchingShifts.map((s, j) => (
          <p key={j} style={{ fontSize: 12 }}>
            {s.last_name}: {moment.tz(s.start, 'America/Denver').format('hh:mm A')} -{' '}
            {moment.tz(s.end, 'America/Denver').format('hh:mm A')}
          </p>
        ))}
      </div>
    ),
  }));

  /*const backgroundEvents = doctorShifts.map(t => ({
    title: '',
    start: new Date(t.start),
    end: new Date(t.end),
    resourceId: String(t.schedule_column_id),
  }));*/

  const formats = {
    eventTimeRangeFormat: () => {},
  };

  return (
    <div className="schedule h-100">
      <Navbar selectedTab="schedule" />
      <div className="p-3 h-100">
        {isLoading && <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle" />}
        {!isLoading && errorMessage && <p className="error flex-centered mt-5">{errorMessage}</p>}
        {!isLoading && !errorMessage && (
          <React.Fragment>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <div className="mt-2 d-flex align-items-center">
                  {Boolean(pressureGaugeStats && pressureGaugeStats.capacityPercentage) && (
                    <React.Fragment>
                      <div style={{ width: '50px' }}>
                        <Icon name="gauge" className={pressureGaugeStats.color} />
                      </div>
                      <p style={{ fontSize: '22px' }} className="roboto">
                        <nobr>{pressureGaugeStats.capacityPercentage}%</nobr>
                      </p>
                    </React.Fragment>
                  )}
                </div>
                <div className="me-3">
                  {isLoveland && (
                    <button
                      className="mb-2 btn-text-primary"
                      onClick={() => setShowScheduleTemplaterModal(true)}
                    >
                      Schedule Templater
                    </button>
                  )}
                  <Link to="/cancellation-list">
                    <button className="mb-2 btn-text-primary">Cancellation List</button>
                  </Link>
                  <button
                    className="btn-text-primary"
                    onClick={() => setShowPrintTreatmentSheetModal(true)}
                  >
                    Print Treatment Sheets
                  </button>
                  {/*<button
                    className="mt-2 btn-text-primary"
                    onClick={() => setShowAppointmentFinderModal(true)}
                  >
                    Appointment Finder
                  </button>*/}
                </div>
              </div>
            </div>

            <Calendar
              components={{ toolbar: CustomToolbar }}
              localizer={localizer}
              formats={formats}
              events={[...appointments, ...blockOffs]}
              //backgroundEvents={backgroundEvents}
              defaultView={Views.DAY}
              views={[Views.DAY]}
              dayLayoutAlgorithm={'no-overlap'}
              step={15}
              timeslots={4}
              selectable
              date={date}
              onNavigate={t => {
                const newDate = moment(t).format('YYYY-MM-DD');
                localStorage.setItem('calendarDate', newDate);
                setDate(newDate);
              }}
              onSelectSlot={slot => {
                if (isLoveland) return;
                setSelectedAppt(slot);
                setShowAppointmentModal(true);
              }}
              onSelectEvent={appt => {
                setSelectedAppt(appt);
                if (appt.isBlockOff) {
                  setShowBlockOffModal(true);
                } else {
                  setShowAppointmentModal(true);
                }
              }}
              tooltipAccessor={() => ''}
              min={new Date(2014, 0, 0, 7, 0, 0)}
              max={new Date(2100, 0, 0, 19, 0, 0)}
              startAccessor="start"
              endAccessor="end"
              eventPropGetter={event => {
                return {
                  style: {
                    border: '1px solid gray',
                    boxShadow: '1px 1px 2px 0px gray',
                    borderRadius: '1px',
                    backgroundColor:
                      event.appointment_status === 'Cancelled' ? 'lightgray' : event.color,
                  },
                };
              }}
              resources={resources}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
            />
          </React.Fragment>
        )}
      </div>
      {showScheduleTemplaterModal && (
        <ScheduleTemplater handleClose={() => setShowScheduleTemplaterModal(false)} />
      )}
      {showPrintTreatmentSheetModal && (
        <PrintTreatmentSheetModal
          scheduleColumns={scheduleColumns}
          date={date}
          handleClose={() => setShowPrintTreatmentSheetModal(false)}
        />
      )}
      {showAppointmentFinderModal && (
        <AppointmentFinderModal handleClose={() => setShowAppointmentFinderModal(false)} />
      )}
      {showAppointmentModal && (
        <AppointmentModal appt={selectedAppt} handleClose={() => setShowAppointmentModal(false)} />
      )}
      {showBlockOffModal && (
        <BlockOffModal blockOff={selectedAppt} handleClose={() => setShowBlockOffModal(false)} />
      )}
    </div>
  );
}

export default Schedule;

function CustomToolbar({ date, onNavigate }) {
  const goToBack = () => {
    onNavigate('PREV');
  };

  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToToday = () => {
    onNavigate('TODAY');
  };

  return (
    <div className="rbc-toolbar p-3">
      <div className="rbc-toolbar-label">{moment(date).format('dddd MMMM DD, YYYY')}</div>
      <div className="rbc-btn-group me-3">
        <button className="roboto font-14" onClick={goToToday}>
          Today
        </button>
        <button className="roboto font-18" onClick={goToBack}>
          &lt;
        </button>
        <button className="roboto font-18" onClick={goToNext}>
          &gt;
        </button>
      </div>
      <input
        className="form-control w-auto border p-2 background-white"
        type="date"
        value={date}
        onChange={t => onNavigate('DATE', t.target.value)}
      />
    </div>
  );
}

function PrintTreatmentSheetModal({ scheduleColumns, date, handleClose }) {
  const locationId = localStorage.getItem('locationId');

  const [scheduleColumnId, setScheduleColumnId] = useState('');
  const [results, setResults] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await http.post(
      '/schedule/generateTreatmentSheets',
      { date, scheduleColumnId, locationId },
      { responseType: 'blob' }
    );

    const file = new Blob([response.data], { type: 'application/pdf' });
    const results = URL.createObjectURL(file);
    setResults(results);
    setIsLoading(false);
  };

  if (!results) {
    return (
      <Modal show={true} onHide={handleClose} centered>
        <form>
          <InputSelect
            name="scheduleColumnId"
            value={scheduleColumnId}
            label="Schedule Column"
            optionConfig={[
              { value: '', name: 'Select' },
              ...scheduleColumns.map(t => ({ value: t.id, name: t.name })),
            ]}
            onChange={setScheduleColumnId}
          />
          <ButtonPanel
            primaryButtonText="Submit"
            handleCancel={handleClose}
            handleSubmit={handleSubmit}
            disabled={!scheduleColumnId}
          />
        </form>
      </Modal>
    );
  }

  return (
    <Modal className="modal-auto-width" show={true} onHide={handleClose} centered>
      <form>
        {isLoading && (
          <i className="flex-centered p-5 w-100 fa fa-circle-notch fa-spin fa-2x subtle" />
        )}
        <embed
          src={results}
          className="mt-4"
          type="application/pdf"
          width="1000px"
          height="1150px"
        />
      </form>
    </Modal>
  );
}
