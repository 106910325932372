module.exports = {
  //appointment_status constants
  NOT_CONFIRMED: 1,
  CONFIRMED: 2,
  CANCELLED: 3,
  CHECKED_IN: 4,
  ADMITTED: 5,
  CHECKED_OUT: 6,

  //appointment_type constants
  PRIORITY: 1,
  TECH_APPOINTMENT: 2,
  WELLNESS: 3,
  SICK: 4,
  NEW_PET: 5,
  ADMIT: 6,
  FOLLOW_UP: 7,
  LABS: 8,
  SURGERY: 9,
  SPECIALIST_SURGERY: 10,
  SPECIALIST_CONSULT: 11,
  SPECIALIST_IMAGING: 12,
  QOL_EUTH: 13,
  CONTAGION: 14,
  CONSULTATION: 15,
  EAR_INFECTION: 16,
  LITTER: 17,
  WELLNESS_NEW_PET: 18,
  DENTAL_EVALUATION: 19,
  MASS_EVALUATION: 20,
  INJECTION: 21,
  NAILS_AND_GLANDS: 22,
  HAPPY_VISIT: 23,
  BOOSTER: 24,

  //block_off_type constants
  ADMIT_BLOCK: 1,
  SICK_PET_BLOCK: 2,
  PRIORITY_BLOCK: 3,
  NEW_PET_BLOCK: 4,
  SURGERY_BLOCK: 5,
  SURGERY_SPECIALIST_BLOCK: 6,
  LUNCH_BLOCK: 7,
  GENERAL_BLOCK: 8,
  DOCTOR_OFF_BLOCK: 9,
  ER_BLOCK: 10,
  ALERT_BLOCK: 11,
  MEETING_BLOCK: 12,
  DISCHARGE_BLOCK: 13,
  PLACEHOLDER: 14,
};
