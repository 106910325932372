import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import Input from '../common/input';

import http from '../../services/httpService';

function RabiesCertificate({ petId, clientId, handleClose }) {
  const [email, setEmail] = useState('');
  const [rabiesCertificate, setRabiesCertificate] = useState(null);

  const [emailSucceeded, setEmailSucceeded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getRabiesCertificate = async () => {
      const response = await http.post(
        '/pet/generateRabiesCertificate',
        { petId },
        { responseType: 'blob' }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const pdf = URL.createObjectURL(file);
      setRabiesCertificate(pdf);
    };
    getRabiesCertificate();

    const getClient = async () => {
      const response = await http.get(`/client/getById?client_id=${clientId}`);
      setEmail(response.data.email || '');
    };
    getClient();
  }, [petId, clientId]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setErrorMessage('');
      await http.post('/pet/emailRabiesCertificate', { petId, email });
      setEmailSucceeded(true);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  if (emailSucceeded) {
    return (
      <Modal className="modal-auto-width" show={true} onHide={handleClose} centered>
        <div className="p-5">
          <p className="sura input-width">Rabies certificate has been emailed to {email}</p>
          <button className="mt-3 btn-filled-primary" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal className="modal-auto-width" show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <Input name="email" value={email} label="Email Address" onChange={setEmail} />
        <button className="btn-filled-primary" onClick={handleSubmit} disabled={!email}>
          Send
        </button>
        <embed
          src={rabiesCertificate}
          className="mt-4"
          type="application/pdf"
          width="1000px"
          height="1150px"
        />
      </form>
    </Modal>
  );
}

export default RabiesCertificate;
