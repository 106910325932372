import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import http from '../../services/httpService';

import Page from '../common/page';
import { Mobile, NotMobile } from '../common/responsive';

import { formatCurrency } from '../../util';

function DelinquencyDashboard() {
  const [results, setResults] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getDelinquentHealthPlans = async () => {
      try {
        setIsLoading(true);
        const response = await http.get('/report/getDelinquencyReport');
        setResults(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getDelinquentHealthPlans();
  }, []);

  return (
    <Page selectedTab="admin">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        <h1>Delinquent Plans</h1>
        <h2 className="my-2">Count: {results.length}</h2>
        {isLoading && <i className="flex-centered m-5 fa fa-circle-notch fa-spin fa-2x subtle" />}
        {!isLoading && errorMessage && <p className="error">{errorMessage}</p>}
        {!isLoading && !errorMessage && (
          <React.Fragment>
            <Mobile>
              <React.Fragment>
                {results.map((t, i) => (
                  <div
                    key={i}
                    className="d-flex flex-column border rounded p-3 mt-3"
                    onClick={() => navigate(`/client/${t.clientId}/pet/${t.petId}`)}
                  >
                    <div className="flex-row-aligned justify-content-between">
                      <h2>{`${t.clientFirstName} ${t.clientLastName}`}</h2>
                    </div>
                    <span className="mt-2 ps-3">
                      <span className="sura me-2">Pet:</span>
                      {t.petName}
                    </span>
                    <span className="ps-3">
                      <span className="sura me-2">Amount Due:</span>
                      {formatCurrency(t.amountDue)}
                    </span>
                    <span className="ps-3">
                      <span className="sura me-2">Delinquency Start Date:</span>
                      {t.delinquencyStartDate}
                    </span>
                    <span className="ps-3">
                      <span className="sura me-2">Last Communication:</span>
                      {t.lastCommunication}
                    </span>
                  </div>
                ))}
              </React.Fragment>
            </Mobile>
            <NotMobile>
              <div className="w-100">
                <table className="w-100">
                  <thead className="border-bottom border-top">
                    <tr>
                      <th className="py-3 px-2">Client</th>
                      <th>Pet</th>
                      <th>Amount Due</th>
                      <th>Delinquency Start Date</th>
                      <th>Last Communication</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.map((t, i) => (
                      <tr key={i} className={i % 2 === 0 ? `background-gray` : ''}>
                        <td
                          className="p-2 btn-text-secondary pointer"
                          onClick={() => navigate(`/client/${t.clientId}`)}
                        >
                          {`${t.clientFirstName} ${t.clientLastName}`}
                        </td>
                        <td
                          className="btn-text-secondary pointer"
                          onClick={() => navigate(`/client/${t.clientId}/pet/${t.petId}`)}
                        >
                          {t.petName}
                        </td>
                        <td>{formatCurrency(t.amountDue)}</td>
                        <td>{t.delinquencyStartDate}</td>
                        <td>{t.lastCommunication}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </NotMobile>
          </React.Fragment>
        )}
      </div>
    </Page>
  );
}

export default DelinquencyDashboard;
