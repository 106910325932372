import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import InputCustom from '../common/inputCustom';
import InputSelect from '../common/inputSelect';

import 'react-datepicker/dist/react-datepicker.css';

function AppointmentFinderModal({ handleClose }) {
  const locationId = localStorage.getItem('locationId');

  const [petId, setPetId] = useState('');
  const [appointmentLevel, setAppointmentLevel] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const [appointmentSlots, setAppointmentSlots] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [requestSucceeded, setRequestSucceeded] = useState(false);

  useEffect(() => {
    const getAvailableAppointmentSlots = async () => {
      try {
        setIsLoading(true);

        const response = await http.get(
          `/appointment/getAvailableSlots?appointment_level=${appointmentLevel}&location_id=${locationId}`
        );

        setAppointmentSlots(response.data);
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setIsLoading(false);
      }
    };

    getAvailableAppointmentSlots();
  }, [appointmentLevel, locationId]);

  const handleSubmit = async () => {
    await http.post('/appointment/scheduleAppointmentOnline', {
      petId,
      appointmentLevel,
      date,
      time,
      locationId,
    });
    setRequestSucceeded(true);
  };

  if (requestSucceeded) {
    return (
      <Modal show={true} onHide={handleClose} centered>
        <form>
          <p className="font-18 roboto input-width text-center">
            Thank you for requesting an appointment! A member of our staff will reach out to you
            shortly.
          </p>
        </form>
      </Modal>
    );
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <InputCustom
          selectedId={petId}
          retrievalEndpoint="/pet/getById?pet_id="
          searchEndpoint="/pet/search?"
          displayNameField="displayName"
          label="Pet"
          handleChange={pet => setPetId(pet.id)}
          handleClear={() => setPetId(null)}
        />
        <InputSelect
          name="appointmentLevel"
          value={appointmentLevel}
          label="Appointment Level"
          optionConfig={[
            { value: '', name: 'Select' },
            { value: 'VA', name: 'VA' },
            { value: 'VA4', name: 'VA4' },
            { value: 'Doctor', name: 'Doctor' },
          ]}
          onChange={setAppointmentLevel}
        />
        {isLoading && (
          <i className="p-3 subtle font-18 fa fa-circle-notch fa-spin align-self-center" />
        )}
        {!isLoading && appointmentLevel && (
          <React.Fragment>
            <div className="mb-2 w-100">
              <DatePicker
                minDate={moment().tz('America/Denver').format('MM-DD-YYYY')}
                includeDates={appointmentSlots.map(t =>
                  moment.tz(t.start, 'America/Denver').format('MM-DD-YYYY')
                )}
                selected={date}
                onChange={date => setDate(moment.tz(date, 'America/Denver').format('MM-DD-YYYY'))}
                inline
              />
            </div>
            {appointmentSlots
              .filter(t => moment.tz(t.start, 'America/Denver').format('MM-DD-YYYY') === date)
              .map(t => moment.tz(t.start, 'America/Denver').format('hh:mm A'))
              .map((t, i) => (
                <div key={i} className="radio-line mb-2">
                  <input
                    className="mt-1"
                    type="radio"
                    id={`time-${t}`}
                    name="apptTime"
                    checked={time === t}
                    value={t}
                    onChange={({ target }) => setTime(target.value)}
                  />
                  <label className="fw-bolder news-cycle font-16" htmlFor={`time-${t}`}>
                    {t}
                  </label>
                </div>
              ))}
          </React.Fragment>
        )}
        <ButtonPanel
          primaryButtonText="Submit"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          handleErrorMessage={setErrorMessage}
          disabled={!petId || !appointmentLevel || !date || !time}
        />
      </form>
    </Modal>
  );
}

export default AppointmentFinderModal;
