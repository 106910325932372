import React, { useState } from 'react';
import moment from 'moment-timezone';

import http from '../../../services/httpService';

function ReminderIssues() {
  const [results, setResults] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      const response = await http.post(`/report/getReminderIssuesReport`);
      setResults(response.data);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <button
        className="mb-3 me-5 btn-filled-primary input-width"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        Run
      </button>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {isLoading && (
        <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
      )}
      {!isLoading && (
        <div>
          <h2 className="mb-3">Count: {results.length}</h2>
          {results.length > 0 && (
            <div className="p-2">
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th>Daysmart Pet Id</th>
                    <th>Pet Name</th>
                    <th>Reminder Name</th>
                    <th>Reminder Date</th>
                    <th>Reminder Issue</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((t, i) => (
                    <tr key={i}>
                      <td>{t.daysmart_pet_id}</td>
                      <td>{t.pet_name}</td>
                      <td>{t.reminder_name}</td>
                      <td>{moment.tz(t.reminder_date, 'America/Denver').format('MM-DD-YYYY')}</td>
                      <td>{t.reminder_issue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default ReminderIssues;
