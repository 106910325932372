import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Input from '../common/input';
import InputSelect from '../common/inputSelect';
import Page from '../common/page';

function DoctorSchedule() {
  const locationId = localStorage.getItem('locationId');

  const [results, setResults] = useState([]);

  const [startDate, setStartDate] = useState(
    moment().tz('America/Denver').startOf('week').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().tz('America/Denver').endOf('week').format('YYYY-MM-DD')
  );

  const [selectedDoctorShift, setSelectedDoctorShift] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showDoctorShiftModal, setShowDoctorShiftModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const getDoctorShifts = async () => {
        setIsLoading(true);
        setErrorMessage('');
        const response = await http.get(
          `/schedule/getDoctorShifts?start_date=${startDate}&end_date=${endDate}&location_id=${locationId}`
        );
        const shifts = response.data;

        for (const shift of shifts) {
          shift.hours = (
            moment(shift.end).diff(shift.start, 'hours', true) -
            Number(shift.lunch_allotment) / 60
          ).toFixed(2);
        }

        setResults(shifts);
      };
      getDoctorShifts();
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  }, [startDate, endDate, locationId]);

  const handleDeleteDoctorShift = async () => {
    try {
      setErrorMessage('');
      await http.delete(`/schedule/deleteDoctorShift?id=${selectedDoctorShift.id}`);
      navigate(0);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const getHoursByDoctor = () => {
    const doctors = new Set();

    for (const r of results) {
      doctors.add(r.last_name);
    }

    const hours = [];

    for (const doctor of doctors) {
      const totalHours = results
        .filter(t => t.last_name === doctor)
        .map(t => t.hours)
        .reduce((a, b) => Number(a) + Number(b), 0)
        .toFixed(2);
      hours.push({ doctor, hours: totalHours });
    }

    return (
      <React.Fragment>
        {hours.map((t, i) => (
          <h3 key={i}>
            {t.doctor}: {t.hours}
          </h3>
        ))}
      </React.Fragment>
    );
  };

  return (
    <Page selectedTab="admin">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        <h1 className="pb-4">Doctor Schedule</h1>
        <div className="d-flex">
          <Input
            className="me-2"
            type="date"
            name="startDate"
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
          />
          <Input
            type="date"
            name="endDate"
            label="End Date"
            value={endDate}
            onChange={setEndDate}
          />
          <button
            className="mt-2 ms-2 btn-rounded-primary"
            onClick={() => {
              setStartDate(
                moment
                  .tz(startDate, 'America/Denver')
                  .subtract(1, 'week')
                  .startOf('week')
                  .format('YYYY-MM-DD')
              );
              setEndDate(
                moment
                  .tz(endDate, 'America/Denver')
                  .subtract(1, 'week')
                  .endOf('week')
                  .format('YYYY-MM-DD')
              );
            }}
          >
            Previous Week
          </button>
          <button
            className="mt-2 ms-2 btn-rounded-primary"
            onClick={() => {
              setStartDate(
                moment
                  .tz(startDate, 'America/Denver')
                  .add(1, 'week')
                  .startOf('week')
                  .format('YYYY-MM-DD')
              );
              setEndDate(
                moment
                  .tz(endDate, 'America/Denver')
                  .add(1, 'week')
                  .endOf('week')
                  .format('YYYY-MM-DD')
              );
            }}
          >
            Next Week
          </button>
        </div>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {isLoading && (
          <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
        )}
        {!isLoading && (
          <div className="mt-3">
            <h2 className="mb-2">
              Hours:{' '}
              {results
                .map(t => t.hours)
                .reduce((a, b) => Number(a) + Number(b), 0)
                .toFixed(2)}
            </h2>
            {getHoursByDoctor()}
            <button
              className="mt-3 ms-2 attention"
              onClick={() => {
                setSelectedDoctorShift(null);
                setShowDoctorShiftModal(true);
              }}
            >
              <Icon name="add" />
              Create Doctor Shift
            </button>
            {results.length > 0 && (
              <div className="p-2">
                <table>
                  <thead>
                    <tr className="border-bottom">
                      <th>Date</th>
                      <th>Doctor</th>
                      <th>Column</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Lunch (min.)</th>
                      <th>Hours</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.map((t, i) => (
                      <tr key={i}>
                        <td>{moment.tz(t.start, 'America/Denver').format('MM-DD-YYYY')}</td>
                        <td>{t.last_name}</td>
                        <td>{t.schedule_column}</td>
                        <td>{moment.tz(t.start, 'America/Denver').format('hh:mm A')}</td>
                        <td>{moment.tz(t.end, 'America/Denver').format('hh:mm A')}</td>
                        <td>{t.lunch_allotment}</td>
                        <td>{t.hours}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              onClick={() => {
                                setSelectedDoctorShift(t);
                                setShowDoctorShiftModal(true);
                              }}
                            >
                              <Icon name="edit" className="blue m-0" />
                            </button>
                            <button
                              className="ms-2"
                              type="button"
                              onClick={() => {
                                setSelectedDoctorShift(t);
                                setShowDeleteConfirmationModal(true);
                              }}
                            >
                              <Icon name="delete" className="error m-0" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {showDoctorShiftModal && (
          <DoctorShiftModal
            doctorShift={selectedDoctorShift}
            handleClose={() => setShowDoctorShiftModal(false)}
          />
        )}
        {showDeleteConfirmationModal && (
          <Confirmation
            message="Are you sure you would like to delete this shift?"
            handleConfirm={handleDeleteDoctorShift}
            handleClose={() => setShowDeleteConfirmationModal(false)}
          />
        )}
      </div>
    </Page>
  );
}

export default DoctorSchedule;

function DoctorShiftModal({ doctorShift, handleClose }) {
  const locationId = localStorage.getItem('locationId');
  const isUpdate = Boolean(doctorShift);

  const [date, setDate] = useState(
    isUpdate
      ? moment.tz(doctorShift.start, 'America/Denver').format('YYYY-MM-DD')
      : moment.tz('America/Denver').format('YYYY-MM-DD')
  );
  const [employeeId, setEmployeeId] = useState(isUpdate ? doctorShift.employee_id : '');
  const [scheduleColumnId, setScheduleColumnId] = useState(
    isUpdate ? doctorShift.schedule_column_id : ''
  );
  const [startTime, setStartTime] = useState(
    isUpdate ? moment.tz(doctorShift.start, 'America/Denver').format('HH:mm') : '08:00'
  );
  const [endTime, setEndTime] = useState(
    isUpdate ? moment.tz(doctorShift.end, 'America/Denver').format('HH:mm') : '18:00'
  );
  const [lunchAllotment, setLunchAllotment] = useState(isUpdate ? doctorShift.lunch_allotment : 60);

  const [doctors, setDoctors] = useState([]);
  const [scheduleColumns, setScheduleColumns] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const getDoctors = async () => {
        const response = await http.get(`/employee/getDoctors?is_active='Y'`);
        const doctors = response.data.map(t => ({ value: t.id, name: t.last_name }));
        setDoctors(doctors);
      };
      getDoctors();

      const getScheduleColumns = async () => {
        const response = await http.get(
          `/schedule/getColumns?location_id=${locationId}&is_doctor='Y'`
        );
        const scheduleColumns = response.data.map(t => ({ value: t.id, name: t.name }));
        setScheduleColumns(scheduleColumns);
      };
      getScheduleColumns();
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  }, [locationId]);

  const handleSubmit = async () => {
    const params = {
      employeeId,
      scheduleColumnId,
      start: moment.tz(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm A', 'America/Denver'),
      end: moment.tz(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm A', 'America/Denver'),
      lunchAllotment,
    };

    try {
      if (isUpdate) {
        await http.post(`/schedule/updateDoctorShift`, { id: doctorShift.id, ...params });
      } else {
        await http.post(`/schedule/createDoctorShift`, params);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }

    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        <Input name="date" type="date" value={date} label="Date" onChange={setDate} />
        <InputSelect
          name="employeeId"
          value={employeeId}
          label="Doctor"
          optionConfig={[{ value: '', name: 'Select' }, ...doctors]}
          onChange={setEmployeeId}
        />
        <InputSelect
          name="scheduleColumnId"
          value={scheduleColumnId}
          label="Schedule Column"
          optionConfig={[{ value: '', name: 'Select' }, ...scheduleColumns]}
          onChange={setScheduleColumnId}
        />
        <Input type="time" value={startTime} onChange={setStartTime} label="Start Time" />
        <Input type="time" value={endTime} onChange={setEndTime} label="End Time" />
        <Input
          name="lunchAllotment"
          type="number"
          value={lunchAllotment}
          label="Lunch Allotment"
          onChange={setLunchAllotment}
        />
        <ButtonPanel
          primaryButtonText="Save"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={
            !employeeId || !date || !startTime || !endTime || !scheduleColumnId || !lunchAllotment
          }
        />
      </form>
    </Modal>
  );
}
