import React, { useState } from 'react';
import moment from 'moment-timezone';

import http from '../../../services/httpService';

import Icon from '../../common/icon';
import Input from '../../common/input';
import InputSelect from '../../common/inputSelect';

import { generateCsv } from '../../../util';

function Appts() {
  const locationId = localStorage.getItem('locationId');

  const [results, setResults] = useState([]);

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const [sortBy, setSortBy] = useState('start');
  const [filterType, setFilterType] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      const response = await http.post(`/report/getAppointmentReport`, {
        startDate,
        endDate,
        locationId,
      });
      setResults(response.data);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="d-flex mt-2">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
      </div>
      <button
        className="mb-3 me-5 btn-filled-primary input-width"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        Run
      </button>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {isLoading && (
        <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
      )}
      {!isLoading && (
        <div>
          <h2 className="mb-3">Total Count: {results.length}</h2>
          {results.length > 0 && (
            <div className="ms-3 mb-3">
              <h3>Surgery: {results.filter(t => t.type === 'Surgery').length}</h3>
              <h3>Tech Appt: {results.filter(t => t.type === 'Tech Appt').length}</h3>
              <h3>Admit: {results.filter(t => t.type === 'Admit').length}</h3>
              <h3>Priority: {results.filter(t => t.type === 'Priority').length}</h3>
              <h3>Sick: {results.filter(t => t.type === 'Sick').length}</h3>
              <h3>Wellness: {results.filter(t => t.type === 'Wellness').length}</h3>
            </div>
          )}
          <InputSelect
            name="sortBy"
            value={sortBy}
            label="Sort By"
            optionConfig={['start', 'insert_ts', 'type']}
            rawOptions={true}
            onChange={value => {
              const sorted = results.sort((a, b) => a[value].localeCompare(b[value]));
              setResults(sorted);
              setSortBy(value);
            }}
          />
          <InputSelect
            name="filterType"
            value={filterType}
            label="Filter Type"
            optionConfig={[
              'All',
              'Admit',
              'Priority',
              'Sick Pet',
              'Surgery',
              'Tech Appt',
              'Wellness',
            ]}
            rawOptions={true}
            onChange={value => setFilterType(value === 'All' ? '' : value)}
          />
          {results.length > 0 && (
            <div className="p-2">
              <button
                className="mb-3 me-5 btn-text-primary align-self-end"
                onClick={() => generateCsv(`appts_${startDate}_${endDate}`, results)}
              >
                <Icon name="download" />
                Download
              </button>
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th>Name</th>
                    <th>Client Name</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Create</th>
                    <th>Complaint</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((t, i) => {
                    if (filterType && t.type !== filterType) return null;
                    return (
                      <tr key={i}>
                        <td>{t.pet_name}</td>
                        <td>
                          {t.client_first_name} {t.client_last_name}
                        </td>
                        <td>{t.type}</td>
                        <td>{t.status}</td>
                        <td>{moment(t.start).tz('America/Denver').format('MM/DD hh:mm A')}</td>
                        <td>{moment(t.insert_ts).tz('America/Denver').format('MM/DD hh:mm A')}</td>
                        <td style={{ width: '150px' }}>{t.complaint}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default Appts;
