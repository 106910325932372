import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Input from '../common/input';
import InputSelect from '../common/inputSelect';
import InputTime from '../common/inputTime';
import InputTextarea from '../common/inputTextarea';

import AppointmentConstants from '../../appointmentConstants';

function BlockOffModal({ blockOff, handleClose }) {
  const locationId = localStorage.getItem('locationId');
  const isLoveland = Number(locationId) === 1;
  const isUpdate = Boolean(blockOff.id);

  const [scheduleColumnId, setScheduleColumnId] = useState(blockOff.resourceId);
  const [blockOffTypeId, setBlockOffTypeId] = useState(isUpdate ? blockOff.block_off_type_id : '');
  const [label, setLabel] = useState(isUpdate ? blockOff.label || '' : '');
  const [startDate, setStartDate] = useState(moment(blockOff.start).format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState(moment(blockOff.start).format('HH:mm'));
  const [endDate, setEndDate] = useState(moment(blockOff.end).format('YYYY-MM-DD'));
  const [endTime, setEndTime] = useState(moment(blockOff.end).format('HH:mm'));
  const [notes, setNotes] = useState(isUpdate ? blockOff.notes || '' : '');

  const [scheduleColumns, setScheduleColumns] = useState([]);
  const [blockOffTypes, setBlockOffTypes] = useState([]);

  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const getScheduleColumns = async () => {
        const response = await http.get(`/schedule/getColumns?location_id=${locationId}`);
        const scheduleColumns = response.data.map(t => ({ value: t.id, name: t.name }));
        setScheduleColumns(scheduleColumns);
      };
      getScheduleColumns();

      const getBlockOffTypes = async () => {
        const response = await http.get(`/appointment/getBlockOffTypes`);
        const blockOffTypes = response.data.map(t => ({ value: t.id, ...t }));
        setBlockOffTypes([{ value: '', name: 'Select Type' }, ...blockOffTypes]);
      };
      getBlockOffTypes();
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  }, [locationId]);

  const handleSubmit = async () => {
    const params = {
      scheduleColumnId,
      blockOffTypeId,
      label,
      start: moment.tz(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm A', 'America/Denver'),
      end: moment.tz(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm A', 'America/Denver'),
      notes,
    };

    if (isUpdate) {
      await http.post(`/appointment/updateBlockOff`, { id: blockOff.id, ...params });
    } else {
      await http.post(`/appointment/createBlockOff`, params);
    }

    navigate(0);
  };

  const handleBlockOffTypeChange = blockOffTypeId => {
    setBlockOffTypeId(blockOffTypeId);
    const blockOffType = blockOffTypes.find(t => t.value === blockOffTypeId);
    setLabel(blockOffType.name);
    setEndTime(
      moment
        .tz(startTime, 'hh:mm A', 'America/Denver')
        .add('minutes', blockOffType.default_duration)
        .format('hh:mm A')
    );
  };

  const handleBlockOffCancellation = async () => {
    await http.delete(`/appointment/cancelBlockOff?block_off_id=${blockOff.id}`);
    navigate(0);
  };

  if (showCancellationModal) {
    return (
      <Confirmation
        message="Are you sure you would like to cancel this block off?"
        handleConfirm={handleBlockOffCancellation}
        handleClose={() => setShowCancellationModal(false)}
      />
    );
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        <InputSelect
          name="scheduleColumnId"
          value={scheduleColumnId}
          label="Schedule Column"
          optionConfig={scheduleColumns}
          onChange={setScheduleColumnId}
          disabled={isLoveland}
        />
        <InputSelect
          name="blockOffTypeId"
          value={blockOffTypeId}
          label="Block Off Type"
          optionConfig={blockOffTypes}
          onChange={handleBlockOffTypeChange}
          disabled={isLoveland}
        />
        <Input name="label" value={label} label="Label" onChange={setLabel} disabled={isLoveland} />
        <div className="input-width d-flex">
          <Input
            name="startDate"
            type="date"
            value={startDate}
            label="Start"
            onChange={value => {
              setStartDate(value);
              setEndDate(value);
            }}
            disabled={isLoveland}
          />
          <InputTime
            className="ms-2"
            value={startTime}
            onChange={setStartTime}
            label="Time"
            disabled={isLoveland}
          />
        </div>
        <div className="input-width d-flex">
          <Input
            name="endDate"
            type="date"
            value={endDate}
            label="End"
            onChange={setEndDate}
            disabled={isLoveland}
          />
          <InputTime
            className="ms-2"
            value={endTime}
            onChange={setEndTime}
            label="Time"
            disabled={isLoveland}
          />
        </div>
        <InputTextarea
          name="notes"
          value={notes}
          label="Notes"
          rows="10"
          onChange={setNotes}
          disabled={isLoveland}
        />
        {!isLoveland &&
          isUpdate &&
          Number(blockOff.block_off_type_id) !== AppointmentConstants.SURGERY_BLOCK && (
            <button onClick={() => setShowCancellationModal(true)}>
              <Icon name="fas fa-ban" className="ms-3" tooltipText="Cancel Block Off" />
            </button>
          )}

        {!isLoveland && (
          <ButtonPanel
            primaryButtonText="Save"
            handleCancel={handleClose}
            handleSubmit={handleSubmit}
            handleErrorMessage={setErrorMessage}
            disabled={!blockOffTypeId || !startDate || !startTime || !endDate || !endTime}
          />
        )}
      </form>
    </Modal>
  );
}

export default BlockOffModal;
