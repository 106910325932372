import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Page from '../common/page';

function MissedAlertsReport() {
  const [results, setResults] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getResults = async () => {
      try {
        setIsLoading(true);
        setErrorMessage('');
        const response = await http.post(`/report/getMissedAppointmentAlertsReport`, {
          startDate: moment().tz('America/Denver').subtract(1, 'month').format('YYYY-MM-DD'),
        });
        setResults(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getResults();
  }, []);

  return (
    <Page selectedTab="admin">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        <h1 className="pb-4">Missed Appointment Alerts Report</h1>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {isLoading && (
          <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
        )}
        {!isLoading && results.length > 0 && (
          <div className="p-2">
            <table>
              <thead>
                <tr className="border-bottom">
                  <th>Client Name</th>
                  <th>Name</th>
                  <th>Location</th>
                  <th>Date</th>
                  <th>Complaint</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {results.map((t, i) => (
                  <tr key={i}>
                    <td>
                      {t.first_name} {t.last_name}
                    </td>
                    <td>{t.name}</td>
                    <td>{t.location}</td>
                    <td>{moment(t.start).tz('America/Denver').format('MM/DD/YY hh:mm A')}</td>
                    <td style={{ width: '200px' }}>{t.complaint}</td>
                    <td style={{ width: '300px' }}>{t.notes}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Page>
  );
}

export default MissedAlertsReport;
