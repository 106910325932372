import React, { useState, useEffect } from 'react';

import http from '../../services/httpService';

import Icon from '../common/icon';
import Page from '../common/page';

function PressureGauge() {
  const locationId = localStorage.getItem('locationId');

  const [results, setResults] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPressureGaugeStats = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/report/getPressureGaugeReport?location_id=${locationId}`);
        setResults(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getPressureGaugeStats();
  }, [locationId]);

  const getWeekRow = weeklyStats => {
    return (
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div style={{ width: '40px' }}>
              <Icon name="gauge" className={weeklyStats.color || 'black'} />
            </div>
            {weeklyStats.capacityPercentage !== null && (
              <p className="roboto font-18">{weeklyStats.capacityPercentage}%</p>
            )}
          </div>
        </td>
        <td>{weeklyStats.start}</td>
        <td>{weeklyStats.end}</td>
        <td>{weeklyStats.numRoomAppointments}</td>
        <td>{weeklyStats.numSurgeries}</td>
      </tr>
    );
  };

  return (
    <Page selectedTab="admin">
      <div className="p-5 background-gray box-shadow d-flex flex-column">
        <h1>Pressure Gauge</h1>
        {isLoading && <i className="flex-centered m-5 fa fa-circle-notch fa-spin fa-2x subtle" />}
        {!isLoading && errorMessage && <p className="error">{errorMessage}</p>}
        {!isLoading && !errorMessage && results && (
          <div className="mt-4">
            <p className="roboto font-18">Appointment Queue: {results.apptQueueCount}</p>
            <p className="roboto font-18">Surgery Queue: {results.surgeryQueueCount}</p>

            <div className="mt-5">
              <h2 className="mb-2">1 Week Forecast</h2>
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th></th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Room Appts Booked</th>
                    <th>Surgeries Booked</th>
                  </tr>
                </thead>
                <tbody>
                  {getWeekRow(results.day1Stats)}
                  {getWeekRow(results.day2Stats)}
                  {getWeekRow(results.day3Stats)}
                  {getWeekRow(results.day4Stats)}
                  {getWeekRow(results.day5Stats)}
                  {getWeekRow(results.day6Stats)}
                  {getWeekRow(results.day7Stats)}
                </tbody>
              </table>
            </div>

            <div className="mt-5">
              <h2 className="mb-2">6 Week Forecast</h2>
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th></th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Room Appts Booked</th>
                    <th>Surgeries Booked</th>
                  </tr>
                </thead>
                <tbody>
                  {getWeekRow(results.week1Stats)}
                  {getWeekRow(results.week2Stats)}
                  {getWeekRow(results.week3Stats)}
                  {getWeekRow(results.week4Stats)}
                  {getWeekRow(results.week5Stats)}
                  {getWeekRow(results.week6Stats)}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
}

export default PressureGauge;
